<template>
  <section class="verify-page page-container">
    <div class="flex-col header text-left fw-500  color-dark">
      <span class="lh-150 fs-26">Racing Max</span>
      <span class="lh-150 fs-42 ">鴻朗保險</span>
    </div>
    <div class="content-body">
      <div class="cotent-text text-left">
        <span class="lh-150 mg-b-8">{{detail.policyholder}}</span>
        <span class="lh-150 mg-b-8">{{detail.production_full_name}}</span>
        <span class="lh-150 mg-b-8">{{vehicle_detail.registration_mark}}</span>
        <span class="lh-150">{{vehicle_detail.car_model}}</span>
      </div>
      <div class="button-container">
        <v-button
          icon="download"
          :disabled="!receipt"
          plain
          @click="handleConfirm(receipt)">收據 Receipt</v-button>
        <v-button
          icon="download"
          :disabled="!covernote"
          plain
          @click="handleConfirm(covernote)">臨時保單 Cover Note</v-button>
        <v-button
          v-for="(item,index) in new_policy"
          :key="index"
          icon="download"
          plain
          @click="handleConfirm(item)">{{item.name}}</v-button>
        <v-button
          v-if="new_policy.length<=0"
          icon="download"
          disabled
          plain
        >正式保單 Policy</v-button>
      </div>
    </div>

  </section>
</template>

<script>
import MixinsOptions from '@/mixins/options'
import MixinRules from '@/mixins/rules'
import {appointeeDownload} from '@api/verify'

export default {
  name: 'download',
  mixins:[MixinsOptions,MixinRules],
  data(){
    return{
      detail:{}
    }
  },
  computed:{
    token(){
      let token = localStorage.getItem('token')||''
      return token
    },
    receipt(){
      let{receipt} = this.detail||{}
      return  receipt||''
    },
    vehicle_detail(){
      let{vehicle_detail} = this.detail||{}
      return  vehicle_detail||{}
    },
    new_policy(){
      let{new_policy=[]} = this.detail||{}
      return  new_policy||[]
    },
    policy(){
      let{policy} = this.detail||{}
      return  policy||{}
    },
    ci_policy(){
      let ci_arr = this.new_policy.filter(item=>item.name=='CI')||[]
      return  ci_arr.length>0?ci_arr[0]||{}:''
    },
    se_policy(){
      let ci_arr = this.new_policy.filter(item=>item.name=='Schedule')||[]
      return  ci_arr.length>0?ci_arr[0]||{}:''
    },
    covernote(){
      let{covernote} = this.detail||{}
      return  covernote||''
    },
    nameType(){
      let obj = {
        1:'Policy',
        2:'Cover Note'
      }
      let {type} = this.detail||{}
      return obj[type]
    },
    login_params(){
      let {timestamp,send_type,type,sign,order_no} = this.$route.query||{}
      return {
        timestamp,
        send_type,
        type,
        sign,
        order_no
      }
    }
  },
  watch:{
    token:{
      immediate:true,
      handler(val){
        if(!val)
        {
          this.$router.replace({
            path:'/RacingMax/verify/login',
            query:{
              ...this.login_params
            }
          })
        }
      }
    }
  },
  mounted() {
    // console.log('this.log',this.login_params)
    // return
    this.network().appointeeDownload({token:this.token})
  },
  methods:{
    handleConfirm(data){
      let {url} = data||{}
      if(url)
        window.open(url,'_blank')
    },
    network(){
      return{
        appointeeDownload:async (params) =>{
          let {timestamp,send_type,type,sign,order_no} = this.$route.query||{}
          // this.$router.replace({
          //   name:'verify',
          //   query:{
          //     timestamp,
          //     send_type,
          //     type,
          //     sign,
          //     order_no
          //   }
          // })
          // return
          let {data}  = await appointeeDownload({...params}).catch(err=>{
            if(err){
              let {data} = err
              if(data.code==401){
                this.$router.replace({
                  name:'verify',
                  query:{
                    ...this.login_params
                  }
                })
              }
            }
          })
          this.detail = data
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page-container{
  height: 100vh;
  position: relative;
  max-width: 856px;
  margin: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  .header{
    border-top:5px solid #003EE5;
    border-bottom: 1px solid #F1F1F4;
    padding:38px 0 24px 32px;
  }
  .content-body{
    padding: 32px;
    .cotent-text{
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 175%;
      letter-spacing: 0.02em;
      font-feature-settings: 'pwid' on;
      color: #1A1A1C;
      margin-bottom: 32px;
      span{
        display: block;
      }
    }

    .button-container{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      .el-button{
        margin-left: 0;
        width:calc(50% - 8px);
        margin-bottom: 16px;
      }
      @media screen and (max-width: 768px) {
        .el-button{
          width: 100%!important;
         &:nth-child(2n){
            margin-left:0!important;
          }
        }
      }
    }

  }

}
</style>
